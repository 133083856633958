export default [
    ["#F8C8DC", "#000", "淡珊瑚粉"],
    ["#85C1E9", "#000", "浅海蓝色"],
    ["#C7F8D5", "#000", "淡薄荷绿"],
    ["#E6D6F5", "#000", "淡紫罗兰色"],
    ["#FFE8CC", "#000", "淡杏黄色"],
    ["#FF9999", "#000", "淡鲑鱼粉"],
    ["#ACE1AF", "#000", "淡青瓷绿"],
    ["#D5B4E1", "#000", "淡薰衣草紫"],
    ["#FFF44F", "#000", "淡柠檬黄"],
    ["#AFDCEC", "#000", "淡水蓝色"],
    ["#E9B9B9", "#000", "淡豆沙粉"],
    ["#71BC78", "#000", "淡翡翠绿"],
    ["#C8A2C8", "#000", "淡丁香紫"],
    ["#F7E7CE", "#000", "淡香槟金"],
    ["#48D1CC", "#000", "淡绿松石蓝"],
    ["#FFE4E1", "#000", "淡玫瑰红"],
    ["#6495ED", "#000", "淡矢车菊蓝"],
    ["#FFEDA0", "#000", "淡蜜瓜橙"],
    ["#B4C8A8", "#000", "淡豆沙绿"]
];
