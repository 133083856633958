export default {
	WAIT: '待开始',
	WAIT_CLAIM: '待认领',
	LIVING: '进行中',
	FINISH: '待上传',
	UPLOAD: '待上传',
	AI_REVIEW: '自动化预审中',
	AI_REVIEW_ERROR: '预审驳回',
	AI_REVIEW_ERROR_ACCEPT: '官员审核',
	AI_REVIEW_SUCCESS: '官员审核',
	OFFICIAL_ERROR: '官员驳回',
	OFFICIAL_SUCCESS: '官员审核通过',
	ERROR: '财务驳回',
	SUCCESS: '财务通过'
}